@font-face {
  font-family: 'natodi';
  src:  url('./natodi.eot?3vyoe4');
  src:  url('./natodi.eot?3vyoe4#iefix') format('embedded-opentype'),
    url('./natodi.ttf?3vyoe4') format('truetype'),
    url('./natodi.woff?3vyoe4') format('woff'),
    url('./natodi.svg?3vyoe4#natodi') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'natodi' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-left:before {
  content: "\e900";
}
.icon-people:before {
  content: "\e901";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-logo:before {
  content: "\e903";
}
.icon-house:before {
  content: "\e904";
}
.icon-star:before {
  content: "\e905";
}
.icon-id-card:before {
  content: "\e906";
}
.icon-logo-text:before {
  content: "\e907";
}
.icon-chevron-bottom:before {
  content: "\e908";
}
.icon-plus-circle:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e90a";
}
.icon-info-outlined:before {
  content: "\e90b";
}
.icon-star-big:before {
  content: "\e90c";
}
.icon-comment-text:before {
  content: "\e90d";
}
.icon-call-phone:before {
  content: "\e90e";
}
.icon-x-close:before {
  content: "\e90f";
}
.icon-calendar-history:before {
  content: "\e910";
}
.icon-plus:before {
  content: "\e911";
}
.icon-pencil-create:before {
  content: "\e912";
}
.icon-offices:before {
  content: "\e913";
}
.icon-user-badge:before {
  content: "\e914";
}
.icon-calendar-dates:before {
  content: "\e915";
}
.icon-more-horizontal:before {
  content: "\e916";
}
.icon-eye:before {
  content: "\e917";
}
.icon-atm:before {
  content: "\e918";
}
.icon-creditcard-checked:before {
  content: "\e919";
}
.icon-hourglass:before {
  content: "\e91a";
}
.icon-x-octagon:before {
  content: "\e91b";
}
.icon-chevrons-right:before {
  content: "\e91c";
}
.icon-arrow-left:before {
  content: "\e91d";
}
.icon-menu:before {
  content: "\e91e";
}
.icon-trash-empty:before {
  content: "\e91f";
}
.icon-map-pin:before {
  content: "\e920";
}
.icon-time:before {
  content: "\e921";
}
.icon-money-bill:before {
  content: "\e922";
}
.icon-search:before {
  content: "\e923";
}
.icon-check:before {
  content: "\e924";
}
.icon-toggle-right:before {
  content: "\e925";
}
.icon-copy:before {
  content: "\e926";
}
.icon-checked-circle:before {
  content: "\e927";
}
.icon-chevrons-left:before {
  content: "\e928";
}
.icon-prev:before {
  content: "\e929";
}
.icon-next:before {
  content: "\e92a";
}
.icon-chevron-right:before {
  content: "\e92b";
}
.icon-bell-alert:before {
  content: "\e92c";
}
.icon-image-empty:before {
  content: "\e92d";
}
.icon-globe:before {
  content: "\e92e";
}
.icon-email:before {
  content: "\e92f";
}
.icon-instagram:before {
  content: "\e930";
}
.icon-tiktok:before {
  content: "\e931";
}
.icon-facebook:before {
  content: "\e932";
}
.icon-ask-question:before {
  content: "\e933";
}
.icon-logout:before {
  content: "\e934";
}
.icon-alert-triangle:before {
  content: "\e935";
}
.icon-telegram:before {
  content: "\e936";
}
.icon-refresh:before {
  content: "\e937";
}
